<template>
  <div>
    <div
      v-if="submited==true"
      class="auth-wrapper auth-v1"
    >
      <v-progress-circular
        :size="100"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card
      v-else
      class="mx-auto"
    >
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>
      <v-card-title>
        <b> Création d'utilisateur</b>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="pt-10">
        <v-form
          id="userForm"
          ref="userForm"
          class="multi-col-validation col-md-10 offset-md-1"
        >
          <fieldset class="py-6 m-2">
            <legend class="ml-4 pl-2 pr-2">
              <b>  Informations personnelles </b>
            </legend>
            <v-row class="mx-auto">
              <v-col
                cols="6"
                md="6"
              >
                <v-text-field
                  v-model="user.first_name"
                  label="Nom"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  placeholder="Franck"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="6"
              >
                <v-text-field
                  v-model="user.last_name"
                  label="Prénom"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  placeholder="Louis"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="6"
              >
                <v-text-field
                  v-model="user.phone"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  type="number"
                  label="N° de téléphone"
                  placeholder="673782932"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="6"
              >
                <v-text-field
                  v-model="user.email"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  type="email"
                  label="Email"
                  placeholder="sygalin@gmail.com"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>
          <v-row
            class=""
          >
            <v-col
              cols="12"
              class="d-sm-flex flex-sm-row-reverse"
            >
              <v-btn
                color="primary"
                class="ml-sm-6 col-sm-2 mt-1 mb-2"
                @click="saveUser"
              >
                Créer
              </v-btn>
              <v-btn
                type="button"
                class="ml-sm-6 col-sm-2 mt-1 mb-2"
                outlined
                @click="resetForm"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import Http from '@/helpers/http'
import router from '@/router'
import useDynamicConstant from '@/helpers/useDynamicConstant'

export default {
  name: 'AddFormRefill',
  setup() {
    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const snackbar = {
      show: false,
      message: '',
      color: 'success',
      timeout: 2000,
    }

    const user = reactive({
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      phone: '',
      login: '',
      customer_id: '',
      customerSegmentId: '',
    })

    const submited = false

    const role = JSON.parse(localStorage.getItem('roles'))

    const contrat = JSON.parse(localStorage.getItem('contrats'))

    const client = JSON.parse(localStorage.getItem('customer')).id

    const { ROLE_GESTIONNAIRE } = useDynamicConstant()
    const roleGestionnaire = ROLE_GESTIONNAIRE

    console.log(role)
    console.log(contrat)
    console.log(client)

    return {
      LocalNameRules,
      user,
      snackbar,
      submited,
      role,
      client,
      roleGestionnaire,
    }
  },
  computed: {
    applyRules() {
      if (this.user.typeDeposit !== 1) {
        return this.LocalNameRules
      }

      return []
    },
  },
  watch: {
  },
  created() {

  },

  methods: {
    saveUser() {
      if (this.$refs.userForm.validate()) {
        this.user.login = this.user.phone
        this.user.customer_id = this.client
        this.user.role = this.roleGestionnaire
        this.user.customerSegmentId = JSON.parse(localStorage.getItem('defaultContrat')).id

        console.log(this.user)

        this.submited = true
        Http.post(
          'create-account',
          this.user,
        ).then(rep => {
          this.submited = false
          if (rep.status === 201) {
            this.snackbar.message = 'Enregistrement effectué avec succès'
            this.snackbar.color = 'success'
            this.snackbar.show = true

            setTimeout(() => {
              router.push({ name: 'list-user' })
            }, 1000)
          }
        }).catch(error => {
          this.submited = false
          if (error.status === 422) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          } else {
            this.snackbar.message = 'Votre connexion internet est instable'
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
        })
      } else {
        console.log('erererer')
      }
    },
    resetForm() {
      this.$refs.userForm.reset()
    },
  },
}
</script>
<style lang="scss">
@import '~@/@core/preset/preset/pages/auth.scss';
.errorMessage {
  color: #f20808 !important;
}
</style>
